<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          {{
          $route.params.id == 0
          ? "Nuevo usuario"
          : "User id: " + $route.params.id
          }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol xs="12" md="6">
              <CInput placeholder="Nombre Comercial" label="Nombre Comercial" v-model="form.comercialname" required>
                <template #prepend-content>
                  <font-awesome-icon icon="id-badge" />
                </template>
              </CInput>
              <CInput placeholder="Dirección comercial" label="Dirección comercial" v-model="form.address" required>
                <template #prepend-content>
                  <font-awesome-icon icon="location-arrow" />
                </template>
              </CInput>
              <CInput placeholder="Nombre" label="Nombre" v-model="form.name" required>
                <template #prepend-content>
                  <font-awesome-icon icon="id-card" />
                </template>
              </CInput>
              <CSelect label="Rol" aria-label="Rol" v-model="form.role" :options="role">
                <template #prepend-content>
                  <font-awesome-icon icon="user-tag" />
                </template>
              </CSelect>
              <CSelect 
                v-if="['Vendedor BSmart','Mayorista','Mayorista Vendedor','Distribuidor','Subdistribuidor'].includes(form.role)"
                label="Pertenece a"
                aria-label="Parent"
                v-model="form.parent_id"
                :options="parents"
              >
                <template #prepend-content>
                  <font-awesome-icon icon="user-tag" />
                </template>
              </CSelect>
              <CInput type="email" label="Correo" placeholder="Correo" v-model="form.email" required>
                <template #prepend-content>
                  <font-awesome-icon icon="at" />
                </template>
              </CInput>
              <CInput type="phone" placeholder="Teléfono" label="Teléfono" v-model="form.phone">
                <template #prepend-content>
                  <font-awesome-icon icon="phone" />
                </template>
              </CInput>
              <CInput type="password" placeholder="Contraseña" label="Contraseña" v-model="form.password">
                <template #prepend-content>
                  <font-awesome-icon icon="key" />
                </template>
              </CInput>
              <CInput v-if="sapVendor" type="text" placeholder="Código SAP" label="Código SAP" v-model="form.sapVendor">
                <template #prepend-content>
                  <font-awesome-icon icon="terminal" />
                </template>
              </CInput>
              <CInput type="number" min="0" max="100" placeholder="Retención IVA" label="Retención IVA"
                v-model="form.retencionIva" v-show="vendorVisible">
                <template #prepend-content>
                  <font-awesome-icon icon="percent" />
                </template>
              </CInput>
              <CInput type="number" min="0" max="100" placeholder="Retención Renta" label="Retención Renta"
                v-model="form.retencionRenta" v-show="vendorVisible">
                <template #prepend-content>
                  <font-awesome-icon icon="percent" />
                </template>
              </CInput>
              <CInput type="number" min="0" max="100" placeholder="Comisión" label="Comisión" v-model="form.commission"
                v-show="vendorVisible">
                <template #prepend-content>
                  <font-awesome-icon icon="percent" />
                </template>
              </CInput>
              <CCol col="12" sm="12" lg="12" v-if="$store.state.user.role == 'Administrador'">
                <input type="checkbox" v-model="form.discount_enable" /> Activar descuentos
              </CCol>
              <CCol col="12" sm="12" lg="12">
                <input type="checkbox" v-model="form.isactive" /> Usuario Activo
              </CCol>
              <CCol col="12" sm="12" lg="12" v-show="alert.show">
                <CAlert :color="alert.color" dismissible>
                  {{ alert.message }}
                </CAlert>
              </CCol>
              <CButton color="primary" @click="save">
                <CSpinner v-if="loading" variant="grow" size="sm" />
                Guardar
              </CButton>
            </CCol>
            <CCol xs="12" md="6" class="text-center py-5 d-md-down-none">
              <CCardBody>
                <img src="img/boxes.png" alt="" style="width: 100%">
              </CCardBody>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="goBack">Back</CButton>
        </CCardFooter>
      </CCard>
      <CCard>
        <CCardBody>
          <CDataTable v-if="!loading" hover striped :items="items" :fields="fields" clickable-rows
            @row-clicked="rowClicked">
            <template #status="data">
              <td>
                <CBadge :color="getBadge(data.item.status)">
                  {{ data.item.status }}
                </CBadge>
              </td>
            </template>
            <template #created_at="data">
              <td>
                {{ data.item.created_at | dateFormat }}
              </td>
            </template>
          </CDataTable>

          <CPagination v-if="!loading" :activePage.sync="page" :pages="lastPage" size="sm" align="center"
            @update:activePage="pageChange" />
        </CCardBody>
      </CCard>
    </CCol>
    <CCol sm="12" md="12">
      <CCard>
        <CCardBody>
          <PendingSales :userId="userId" />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Resource from "@/api/resource";
import UserResource from "@/api/user";
import moment from "moment";
import PendingSales from "@/views/dashboard/PendingSales";

const userResource = new UserResource();

export default {
  name: "User",
  components: {
    PendingSales,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.usersOpened = from.fullPath.includes("users");
    });
  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: "name",
          label: "Name",
          _classes: "font-weight-bold",
          sorter: true,
          filter: true,
        },
        {
          key: "email",
          label: "Correo",
          _classes: "font-weight-bold",
          sorter: true,
          filter: true,
        },
        { key: "created_at" },
        { key: "role", sorter: true, filter: true },
        { key: "status", sorter: true, filter: true },
      ],
      usersOpened: null,
      loading: false,
      alert: {
        color: "danger",
        message: "",
        show: false,
      },
      form: {
        comercialname: "",
        address: "",
        name: "",
        email: "",
        role: "",
        phone: "",
        commission: null,
        retencionRenta: null,
        retencionIva: null,
        parent_id: null,
        discount_enable: false,
      },
      role: [
        "Administrador",
        "Vendedor BSmart",
        "Mayorista",
        "Mayorista Vendedor",
        "Distribuidor",
        "Subdistribuidor",
        "Financiero",
        "Soporte",
        "Mercaderista"
      ],
      parents: [],
      showParent: false,
      status: ["Activo", "Inactivo"],
      query: [],
      page: 1,
      perPage: 25,
      lastPage: 1,
    };
  },
  filters: {
    dateFormat: function (value) {
      return value ? moment(value).format("YYYY-MM-DD HH:mm") : "";
    },
  },
  computed: {
    userId() {
      return this.$route.params.id;
    },
    userData() {
      const id = this.$route.params.id;
      const user = usersData.find((user, index) => index + 1 == id);
      const userDetails = user ? Object.entries(user) : [["id", "Not found"]];
      return userDetails.map(([key, value]) => {
        return { key, value };
      });
    },
    visibleData() {
      return this.userData.filter((param) => param.key !== "username");
    },
    username() {
      return this.userData.filter((param) => param.key === "username")[0].value;
    },
    vendorVisible() {
      return (
        (this.form.role == "Distribuidor" || this.form.role == "Mayorista") &&
        (this.$store.state.user.role == "Administrador" ||
          this.$store.state.user.role == "Financiero")
      );
    },
    sapVendor() {
      return (
        (this.form.role == "Administrador" || this.form.role == "Vendedor BSmart")
      );
    },
  },
  watch: {
    "form.role": async function (newValue, oldValue) {
      if (
        newValue != oldValue &&
        newValue != null &&
        newValue != "Administrador" &&
        newValue != "Financiero" &&
        newValue != "Soporte"
      ) {
        const index = this.role.indexOf(newValue);
        let parentRole = null;

        if (index >= 0) {
          parentRole = this.role[index - 1];
          if(this.$store.state.user.role == "Mercaderista"){
            parentRole = "Mayorista Vendedor";
          }
          await this.getParentsByRole(parentRole);
        }
        this.showParent = parentRole != null;
      }

      if (newValue == "Administrador") {
        this.parents = [{ name: '', value: null }];
        this.form.parent_id = null;
      }
      
      if (newValue == "Financiero" || newValue == "Soporte" || newValue == "Mercaderista") {
        this.parents = [{ label: 'Administrador', value: 1 }];
        this.form.parent_id = 1;
      }
    },
  },
  mounted() {
    if (this.$route.params.id != 0) {
      this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
      this.loadClient();
      this.getChilds();
    }
    if (this.$store.state.user.role == "Mayorista") {
      this.role = ["Mayorista Vendedor", "Distribuidor"];
      this.form.role = "Distribuidor";
    }
    if (this.$store.state.user.role == "Mayorista Vendedor") {
      this.role = ["Distribuidor"];
      this.form.role = "Distribuidor";
    }
    if(this.$store.state.user.role == "Mercaderista") {
      this.role = ["Distribuidor"];
      this.form.role = "Distribuidor";
    }
    if (this.$store.state.user.role == "Vendedor BSmart") {
      this.role = ["Mayorista"];
      this.form.role = "Mayorista";
    }
    if (this.$store.state.user.role == "Distribuidor") {
      this.role = ["Subdistribuidor"];
      this.form.role = "Subdistribuidor";
    }
    if (this.$store.state.user.role == "Distribuidor") {
      this.role = ["Subdistribuidor"];
      this.form.role = "Subdistribuidor";
    }
  },
  methods: {
    async getChilds() {
      await userResource.childs({
        id: this.$route.params.id,
        page: this.page,
        perPage: this.perPage,
        search: this.search,
      }).then((response) => {
        this.items = response.data.data;
        this.lastPage = response.data.last_page;
      }).catch((error) => {
        console.log(error);
      });

    },
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/users" });
    },
    async loadClient() {
      await userResource
        .get(this.$route.params.id)
        .then((response) => {
          this.form = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          //console.log(error);
          this.loading = false;
        });
    },
    save() {
      this.loading = true;
      if (this.$route.params.id == 0) {
        userResource
          .store(this.form)
          .then((response) => {
            this.alert.show = true;
            this.alert.message = "Se guardó correctamente";
            this.alert.color = "success";
            this.loading = false;
            this.goBack();
          })
          .catch((error) => {
            this.alert.message = "Ocurrió un error al guardar";
            this.alert.color = "danger";
            this.loading = false;
            this.goBack();
          });
      } else {
        userResource
          .update(this.$route.params.id, this.form)
          .then((response) => {
            this.alert.show = true;
            this.alert.message = "Se guardó correctamente";
            this.alert.color = "success";
            this.loading = false;
            this.goBack();
          })
          .catch((error) => {
            this.alert.message = "Ocurrió un error al guardar";
            this.alert.color = "danger";
            this.loading = false;
            this.goBack();
          });
      }
    },
    async getParentsByRole(parentRole) {
      this.loading = true;
      try {
        const response = await userResource.get(
          `parentsByRole?parent_role=${parentRole}`
        );
        if (response.status == 200) {
          const { data } = response;
          this.parents = data;
          console.log(this.parents);
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async pageChange(val) {
      this.$router.push({ query: { page: val } });
      await this.getChilds();
      this.page = val;
    },
    rowClicked(item, index) {
      this.$router.push({ path: `/users/${item.id}` });
    },
    getBadge(status) {
      switch (status) {
        case "Activo":
          return "success";
        case "Inactivo":
          return "secondary";
        case "Pending":
          return "warning";
        case "Banned":
          return "danger";
        default:
          "primary";
      }
    },
  },
};
</script>
